// import { cibEyeem } from '@coreui/icons'
// import CIcon from '@coreui/icons-react'
import { CButton, CFormInput, CInputGroup } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { allowUser } from 'src/Slices/user'
import Logo from 'src/assets/images/logo.webp'

const ProtectLayer = () => {

    const dispatch = useDispatch()
    const [error,setError] = useState(false)
    const { pathname } = useLocation()

    useEffect(()=>{
        let token = localStorage.getItem('token') || null
        if(token)
            dispatch(allowUser({canAccess : true}))
    },[])

    const handleSubmit = e =>{
        e.preventDefault()
        const form = new FormData(e.target)
        if(form.get('password') === 'Harvok2024@'){
            setError(false)
            dispatch(allowUser({ canAccess : true}))
        }else{
            setError(true)
        }
    }


    return (
        window.localStorage.getItem("token") ? <Navigate to={pathname} /> :
        <div className='vh-100 d-flex p-3 flex-column justify-content-center align-items-center' style={{ backgroundColor: '#f5f5f5' }}>
            <div>
            <div className='text-center mb-5'>
            <img src={Logo} alt='logo' width={290} />
          </div>
                <div>
                        <h4 className='text-center'>The System is upgrading, you will be notified as soon as it is finished</h4>
                        <br/>
                    </div>
                
                </div>
            <form onSubmit={handleSubmit} className='text-center' style={{maxWidth: '300px'}}>
                <div class="form-group mb-3">
                    <label>Enter Password to access App</label>
                    <CInputGroup className="mt-1">
                        <CFormInput
                            type="password"
                            placeholder="Password"
                            id="password"
                            name='password'
                            className='text-center'
                            required
                        />
                        {/*<CInputGroupText>
                        <i class="fa-solid fa-eye-slash"></i>
                        </CInputGroupText>*/}
                    </CInputGroup>
                    {error && <small className='text-danger'>Password Incorrect!</small>}
                </div>
                <div className='text-center'>
                    <CButton className='btn btn-primary' type='submit'>Submit</CButton>
                </div>
            </form>
        </div>
    )
}

export default ProtectLayer